<template>
    <b-container class="sign-content-container">
        <b-row>
            <b-col><h1>Unterschrift</h1></b-col>
        </b-row>
        <b-row>
            <p class="no-margin">Mit dieser Unterschrift stimmst Du zu, dass Du entweder auf dem genannten Sticker abgebildet bist oder ein gesetzlicher Vormund/Erziehungsberechtigter dieser Person bist.</p>
        </b-row>
        <b-row>
            <b-col cols="12">
                <div class="checkbox-wrapper">
                    <div class="min-form-margin">
                        <b-form-checkbox
                                class="age-checkbox"
                                id="checkbox-1"
                                v-model="formData.legalAge"
                                name="checkbox-1"
                                value="OLDER_THAN_18"
                                unchecked-value="">Ich bin volljährig</b-form-checkbox>
                        <b-form-checkbox class="form-internal-margin age-checkbox"
                                id="checkbox-2"
                                v-model="formData.legalAge"
                                name="checkbox-2"
                                value="PARENTAL_AUTH"
                                unchecked-value="">Ich bin erziehungsberechtigt</b-form-checkbox>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="signature form-internal-margin">
                <div class="signature-background">
                    Hier unterschreiben
                </div>
                <VueSignaturePad
                        class="signature-area"
                        id="signature"
                        height="90px"
                        width="100%"
                        ref="signaturePad"
                        :options="options"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-link class="reset-button" @click="resetSignature">zurücksetzen</b-link>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="align-left"><b-button class="sf-secondary-button form-margin" @click="back">Zurück</b-button></b-col>
            <b-col class="align-right"><b-button class="sf-primary-button form-margin" v-bind:class="{ disabled: isInvalid }" @click="next">Weiter</b-button></b-col>
        </b-row>
    </b-container>
</template>

<script>

    export default {
        name: "SignStep3",
        data: () => ({
            formData: {
                legalAge: '',
            },
            options: {
                penColor: "#051630"
            },
            isInvalid: true
        }),
        watch: {
            "formData.legalAge": function() {
                this.isInvalid = this.formData.legalAge != 'OLDER_THAN_18' && this.formData.legalAge != 'PARENTAL_AUTH'
            }
        },
        methods: {
            resetSignature() {
                this.$refs.signaturePad.undoSignature();
            },
            next() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                if(isEmpty)
                    console.log("Empty Sign");

                this.formData.imageData = data;
                this.$emit('next', this.formData)
            },
            back() {
                this.$emit('back')
            }
        },
    }
</script>

<style scoped lang="scss">
    .signature {
        display: flex;
        justify-content: center;
        .signature-background {
            display: flex;
            justify-content: center;
            align-items: center;
            position:absolute;

            height: 90px;
            z-index: -100;
            color: lightgray;
            width: 100%;
        }
        .signature-area {
            border: 1px solid lightgray;
        }
    }

    .checkbox-wrapper {
        display: flex;
        justify-content: center;
        div {
            text-align: left;
        }
        /*display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: flex-start;*/
    }

    .reset-button {
        color: #E8C224;
    }

    .no-margin {
        margin: 0;
    }

    .min-form-margin {
        margin-top: 20px;
    }
</style>
