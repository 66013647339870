import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import underscore from 'vue-underscore';
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VueSignature from "vue-signature-pad";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSignature);
Vue.use(underscore);
// Global registration
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
