<template>
    <b-container class="sign-content-container">
        <b-row>
            <b-col><h1>Daten der abgebildeten Person</h1></b-col>
        </b-row>
        <b-row>
            <b-col>
                <p>Im Online-Shop von stickerfive hast Du die Möglichkeit, Dein Sticker-Porträt auf Produkten wie Postern, Tassen, etc. produzieren zu lassen. Um dies zu ermöglichen bitten wir Dich das folgende Formular online auszufüllen.</p>
                <p>
                    Alternativ kannst Du das Formular auch <a href="Erklaerung_Stickerfive.pdf">hier</a> herunterladen und händisch ausfüllen.
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" sm="8">
                <vue-bootstrap-typeahead class="completebox form-internal-margin"
                        :data="users"
                        v-model="userSearch"
                        size="md"
                        :serializer="s => s.fullname"
                        placeholder="Name"
                        @hit="formData.name = $event"
                />
            </b-col>
            <b-col cols="12" sm="4">
                <b-form-input v-model="formData.number" placeholder="Stickernummer" class="form-internal-margin"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="align-right"><b-button class="sf-primary-button form-margin" v-bind:class="{ disabled: isInvalid }" @click="next">Weiter</b-button></b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {_} from 'vue-underscore';

    const API_URL = 'http://localhost:8080/privacy-agreement-webapp/backend/teamshoot/users?key=:query'
    export default {
        name: "TeamshootSignStep1",
        data: () => ({
            formData: {
                name: "",
                number: ""
            },
            users: [],
            userSearch: '',
            isInvalid: true
        }),
        props: [
            'initialData'
        ],
        watch: {
            'formData.name': function() {
                this.checkValidity();
            },
            'formData.number': function() {
                this.checkValidity();
            },
            // eslint-disable-next-line no-undef
            userSearch: _.debounce(function(addr) { this.getUsers(addr) }, 500)
        },
        methods: {
            next() {
                if(!this.isInvalid)
                    this.$emit('next', this.formData)
            },
            checkValidity() {
                console.log(this.formData.name);
                this.isInvalid = !(this.formData.name != undefined && this.formData.number.trim().length > 0);
            },
            async getUsers(query) {
                const res = await fetch(API_URL.replace(':query', query))
                const suggestions = await res.json()
                this.users = suggestions
            }
        },
        created() {
            if(this.initialData['name'] !== undefined)
                this.formData = this.initialData;
        }
    }
</script>

<style lang="scss">
    .active {
        background-color: #E8C224 !important;
        border-color: #E8C224 !important;
    }
</style>
