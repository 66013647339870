<template>
  <div id="app">
    <nav class="navbar">
      <a class="navbar-brand" href="https://stickerfive.de">
        <img src="./assets/letterBlack.svg"  height="38" class="company-logo" alt="Stickerfive">
      </a>
    </nav>
    <div class="main-area">
      <TeamshootSignComponent class="sign">

      </TeamshootSignComponent>
    </div>
  </div>
</template>

<script>

import TeamshootSignComponent from "@/components/TeamshootSignComponent";
export default {
  name: 'App',
  components: {
    TeamshootSignComponent
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@200;400;600;700&display=swap');

  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 380px;
  }

  #app {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Exo 2', sans-serif !important;
    font-size: 1.08rem !important;
  }

  .main-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .sign {
        max-width: 600px;
    }
    @media (min-height: 550px) and (min-width: 750px) {
      display: flex;
      align-items: center;
      justify-content: center;
      .sign {
        box-shadow: 0 0 10px lightgray;
      }
    }
  }

  h1 {
    font-weight: bold !important;
    font-size: 1.6rem !important;;
    margin-top: 0 !important;;
  }

  .navbar {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #e8e9eb;
    .navbar-brand img {
      margin: 8px;
      max-width: 185px;
      padding-left: 22px;
      @media(min-width: 1000px) {
        margin-left: 55px;
      }
  }
}

</style>
