<template>
    <b-container class="sign-content-container">
        <b-row>
            <b-col><p>Um eine Bestellung im Online-Shop zu tätigen, kannst Du den Link kopieren und im Bestellprozess einfügen oder das PDF herunterladen und bei Deiner Bestellung hochladen.</p></b-col>
        </b-row>
        <b-row>
            <b-col><a :href="link" download="stickerfive_Einverständniserklärung.pdf"><b-button class="sf-secondary-button">Download</b-button></a></b-col>
            <b-col><b-button class="sf-secondary-button" @click="copy">Link kopieren</b-button></b-col>
        </b-row>
        <b-row>
            <b-col>
                <embed :src="link" class="embed-pdf"/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "SignFinished",
        methods: {
            copy() {
                this.copyToClipboard(this.link);
            },
            copyToClipboard(text) {
                let dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
            }
        },
        props: ['link']
    }
</script>

<style scoped lang="scss">
    .embed-pdf {
        width: 100%;
        height: 100%;
        min-height: 400px;
        margin-top: 25px;

        @media (max-width: 750px) {
            display: none;
        }
    }
</style>
