<template>
    <b-container class="sign-content-container">
        <b-row>
            <b-col><h1>Persönliche Daten</h1></b-col>
        </b-row>
        <b-row>
            <b-col>
                <p>Deine persönlichen Daten sind uns wichtig.l Aus diesem Grund ist dieser Schritt erforderlich um Bestellungen
                    von unberechtigten Personen zu vermeiden.
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-input v-model="formData.name" placeholder="Vorname und Nachname" class="form-margin"></b-form-input>
            </b-col>

            <b-col cols="12" sm="8">
                <b-form-input v-model="formData.street" placeholder="Straße" class="form-internal-margin"></b-form-input>
            </b-col>
            <b-col cols="12" sm="4">
                <b-form-input v-model="formData.number" placeholder="Hausnummer" class="form-internal-margin"></b-form-input>
            </b-col>

            <b-col cols="12" sm="4">
                <b-form-input v-model="formData.postcode" placeholder="PLZ" class="form-internal-margin"></b-form-input>
            </b-col>
            <b-col cols="12" sm="8">
                <b-form-input v-model="formData.city" placeholder="Ort" class="form-internal-margin"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="align-left"><b-button class="sf-secondary-button form-margin" @click="back">Zurück</b-button></b-col>
            <b-col class="align-right">
                <b-button class="sf-primary-button form-margin" v-bind:class="{ disabled: isInvalid }" @click="next">
                    Weiter
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "SignStep2",
        data: () => ({
            formData: {
                name: "",
                street: "",
                number: "",
                city: "",
                postcode: ""
            },
            isInvalid: true
        }),
        props: ['initialData'],
        watch: {
            'formData.name': function() {
                this.checkValidity();
            },
            'formData.city': function() {
                this.checkValidity();
            },
            'formData.postcode': function() {
                this.checkValidity();
            },
            'formData.street': function() {
                this.checkValidity();
            },
            'formData.number': function() {
                this.checkValidity();
            }
        },
        methods: {
            next() {
                if(!this.isInvalid)
                    this.$emit('next', this.formData)
            },
            back() {
                this.$emit('back')
            },
            checkValidity() {
                this.isInvalid = !(this.formData.name.trim().length > 0 &&
                    this.formData.street.trim().length > 0 &&
                    this.formData.number.trim().length > 0 &&
                    this.formData.city.trim().length > 0 &&
                    this.formData.postcode.trim().length > 0);
            }
        },
        created() {
            if(this.initialData['name'] !== undefined)
                this.formData = this.initialData;
        }
    }
</script>

<style scoped>

</style>
