<template>
    <div>
        <TeamshootSignStep1 v-if="currentStep == 1" @next="next" @back="back" :initial-data="step1Data"></TeamshootSignStep1>
        <SignStep2 v-if="currentStep == 2" @next="next" @back="back" :initial-data="step2Data"></SignStep2>
        <SignStep3 v-if="currentStep == 3" @next="next" @back="back" :initial-data="step3Data"></SignStep3>
        <SignFinished v-if="currentStep == 4" :link="link"></SignFinished>
    </div>
</template>

<script>
    import SignStep3 from "./SignStep3";
    import SignStep2 from "./SignStep2";
    import TeamshootSignStep1 from "./TeamshootSignStep1";
    import SignFinished from "./SignFinished";

    export default {
        name: "TeamshootSignComponent",
        components: {SignFinished, TeamshootSignStep1, SignStep2, SignStep3},
        data: () => (
            {
                currentStep: 1,
                step1Data: {},
                step2Data: {},
                step3Data: {},
                link: null
            }
        ),
        methods: {
            next(data) {
                if(this.currentStep == 1) {
                    this.currentStep++;
                    this.step1Data = data;
                } else if(this.currentStep == 2) {
                    this.currentStep++;
                    this.step2Data = data;
                } else if(this.currentStep == 3) {
                    this.step3Data = data;
                    this.axios.post('backend/assignment', {
                        personName: this.step1Data.name,
                        stickerNumber: this.step1Data.number,
                        signerName: this.step2Data.name,
                        signerAdress: this.step2Data.street + " " + this.step2Data.number,
                        signerCity: this.step2Data.postcode + " " + this.step2Data.city,
                        imageData: this.step3Data.imageData,
                        legalAge: this.step3Data.legalAge
                    }).then((response) => {
                        this.link = response.data.link;
                        this.currentStep++;
                    }).catch(function () {
                        console.log("ERRORS OCCURED");
                    });

                }
            },
            back() {
                this.currentStep--;
            }
        }
    }
</script>

<style lang="scss">
    .sign-content-container {
        padding: 40px !important;
        text-align: center;

        @media (max-width: 750px) {
            padding: 20px !important;
        }
    }

    .align-right {
        display: flex;
        justify-content: flex-end;
    }

    .align-left {
        display: flex;
        justify-content: flex-start;
    }


    .sf-secondary-button {
        min-width: 145px;
        min-height: 45px;
        background-color: #373737 !important;
        font-weight: bold !important;
        border: 0 !important;
        border-radius: 2px !important;
    }

    .sf-primary-button {
        min-width: 145px;
        min-height: 45px;
        background-color: #E8C224 !important;
        font-weight: bold !important;
        border: 0 !important;
        border-radius: 2px !important;
    }

    .form-internal-margin {
        margin-top: 15px;
    }

    .form-margin {
        margin-top: 45px;
        @media (max-width: 564px) {
            margin-top: 25px;
        }
    }
</style>

